import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Button from '../components/atoms/inputs/Button';
import Spacer from '../components/atoms/utility/Spacer';
import SpeakerApplicant from '../components/organisms/SpeakerApplicant';

export default function DevonBleibtreyPage({
  data: {
    heroImg,
    mobileHeroImg,
    panelOneImg,
    panelTwoImg,
    logo1,
    logo2,
    logo3,
    logo4,
    jaredImage,
    kevinImage,
  },
}) {
  const partnerImages = [logo1, logo2, logo3, logo4];
  const people = [
    {
      name: 'Jared',
      image: jaredImage,
      alt: 'Jared',
      text: "Devon's insights into AI are unparalleled. A true visionary!",
    },
    {
      name: 'Kevin',
      image: kevinImage,
      alt: 'Kevin',
      text: "With flockx, Devon is reshaping how we think about building community. It's revolutionary",
    },
  ];

  return (
    <BaseLayout
      heroContent={DevonBleibtreyHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
      heroAlt="Devon Bleibtrey: Leading the Generative AI Revolution giving a speech"
      partnerImages={partnerImages}
    >
      <SEO
        title="Devon Bleibtrey: Leading the Generative AI Revolution with Flockx.io"
        keywords={[
          'Generative AI',
          'Community',
          'Social GPS',
          'Network of Communities',
          'Devon Bleibtrey',
          'Flockx',
          'digital startups',
          'Central Michigan University',
          'Guest Speaker',
          'Generative AI revolution',
          'community-based platform',
          'event speaker',
          'AI keynote speaker',
        ]}
        description={
          'Invite Devon Bleibtrey, a pioneering leader in AI and CEO of Flockx, as a guest speaker at your event. Dive into the transformative power of AI in creating sustainable communities and learn from the visionary reshaping how communities engage.'
        }
      />

      <div>
        <Spacer sizeY={1} className="block xl:block" />
        <Spacer className="block sm:hidden" />

        <div
          id="learn-more"
          className="flex flex-col 2xl:grid gap-10 lg:gap-24 grid-cols-16 justify-center items-center"
        >
          <div className="col-span-8 text-center 2xl:text-left mb-20 lg:mb-0 px-4 max-w-2xl mx-auto flex flex-col gap-4">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2]">
              Your <span className="text-highlight">Guest Speaker</span>
            </h3>
            <p className="text-lg sm:text-2xl text-highlight block lg:hidden 2xl:block">
              Bring the <span className="font-bold">Future of AI</span> to your
              audience with <span className="font-bold">Devon Bleibtrey</span>
            </p>
            <p className="text-lg sm:text-2xl">
              With a rich legacy in digital start-ups and a Computer Science
              degree from Central Michigan University, Devon stands at the
              forefront of the AI revolution.
            </p>
            <p className="text-lg sm:text-2xl">
              As the CEO of Flockx, he's championing a movement where AI meets
              community building, reshaping how individuals connect. Helping
              people find real world places where the people they want to
              be friends with are at right now.
            </p>
          </div>
          <div className="col-span-8 mx-auto max-w-2xl">
            <GatsbyImage
              image={getImage(panelOneImg)}
              alt={
                'Devon Bleibtrey smiling and laughing while discussing generative AI with people.'
              }
              className="rounded-xl"
            />
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="flex flex-col 2xl:grid gap-10 lg:gap-24 grid-cols-16 justify-center items-center">
          <div className="col-span-8 order-2 2xl:order-1 mx-auto max-w-4xl">
            <GatsbyImage
              image={getImage(panelTwoImg)}
              alt={
                'The Flockx Logo Which Is Devon Bleibtrey Company That Is leveraging Generative AI'
              }
              className="rounded-xl"
            />
          </div>
          <div className="col-span-8 text-center 2xl:text-left mb-20 lg:mb-0 px-4 md:px-2 order-1 2xl:order-2 max-w-3xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-4">
              Innovating With <span className="text-default">Flockx</span>
            </h3>
            <p className="text-lg sm:text-2xl mb-4">
              As a community-based platform dedicated to seamlessly connecting
              individuals with others who share their interests organically
              at places they hang out, our cutting-edge AI
              tools for engagement ensure that{' '}
              <span className="font-bold">Flockx</span> leads the way in
              transformative connectivity and local engagement.
            </p>
            <Link to="/">
              <Button label={'Visit Flockx'} className={'mt-4'} />
            </Link>
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
            Captivate your Audience
          </h3>
          <p className="text-lg sm:text-2xl md:text-3xl lg:text-4xl mb-8 lg:mb-12 max-w-5xl xl:max-w-[1155px] mx-auto">
            Devon, a prominent figure in AI, is more than a leader
            and a dynamic speaker. His talks meaningfully weave technical
            knowledge with real-world relevance, captivating and inspiring
            audiences.
          </p>
        </div>
        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="flex flex-col 2xl:grid gap-10 lg:gap-24 grid-cols-16 justify-center items-center">
          <div className="col-span-8 text-center 2xl:text-left mb-20 lg:mb-0 px-4 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Be at the Forefront of the{' '}
              <span className="text-highlight">AI Revolution.</span>
            </h3>
          </div>
          {/* YouTube Video */}
          <div className="w-full mx-auto max-w-2xl col-span-8">
            <iframe
              width="100%"
              height="395"
              src="https://www.youtube.com/embed/3vNO_4510WE"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-xl"
            ></iframe>
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />
        <div className="p-2 py-12 md:p-8 md:py-12 bg-gray-100 shadow-md rounded-lg">
          <h1 className="text-2xl font-bold text-center mb-4 text-default">
            What do others have to say?
          </h1>
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-semibold text-grey-100 text-center mb-8">
            Industry Voices
          </h2>
          <div className="flex lg:flex-row flex-col justify-center gap-4 md:gap-8 xl:gap-40">
            {people.map((person) => (
              <div
                key={person.name}
                className="bg-white shadow-lg lg:max-w-[500px] p-4 py-8 md:py-4 flex md:flex-row flex-col gap-4 items-center justify-between rounded-lg"
              >
                <GatsbyImage
                  image={getImage(person.image)}
                  alt={person.alt}
                  className="w-[140px] h-[140px] rounded-full mb-4"
                />
                <div className="px-4 md:px-0 flex flex-col items-center md:items-start justify-center md:justify-start w-full md:w-3/4 lg:w-2/3 gap-4">
                  <h3 className="text-xl font-bold">{person.name}</h3>
                  <p className="text-lg md:min-h-[72px] text-center md:text-left">{person.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Spacer className="block sm:hidden" />
        <Spacer sizeY={1} />
        <div className="mx-auto w-full flex items-center justify-center">
          <SpeakerApplicant />
        </div>
        <Spacer className="block sm:hidden" />
        <Spacer />
      </div>
    </BaseLayout>
  );
}

function DevonBleibtreyHeroContent() {
  return (
    <>
      <div className="-mt-[30%] sm:mt-[5%] lg:mt-24 md:grid grid-cols-16">
        <div className="text-center sm:text-left sm:mt-6 lg:mt-0 col-start-7 col-span-9">
          <div className="mb-10 md:mb-0">
            <h1
              className={
                'text-5xl lg:text-6xl xl:text-[72px] leading-[1.3] md:leading-[1.4] font-light text-white mb-2 sm:mb-4'
              }
            >
              <span className="block">Building Collective Knowledge</span>
              <span className="block"> with Community AIs</span>
            </h1>

            <h2 className="block sm:hidden lg:block text-lg sm:text-xl md:text-2xl xl:text-[32px] md:leading-[1.3] lg:leading-[1.6] xl:leading-[48px] mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[240px] sm:max-w-sm lg:max-w-3xl xl:max-w-xl sm:mx-0">
              Pioneering Networks of Local Communities
            </h2>
            <a
              id="free-consultation-btn"
              href="#learn-more"
              className="mb-4 md:mb-6 inline-block lg:hidden 2xl:inline-block"
            >
              <Button label="Book Devon Now" />
            </a>
            <p className="text-xl text-white block lg:hidden 2xl:block">
              Bring the <span className="font-bold">Future of AI</span> to your
              audience with <span className="font-bold">Devon Bleibtrey</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query DevonBleibtreyPageQuery {
    heroImg: file(name: { eq: "devon-speaking" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImg: file(name: { eq: "devon-bleibtrey-mobile-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelOneImg: file(name: { eq: "devon-smiling-and-laughing" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelTwoImg: file(name: { eq: "flockx-event-sign" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    logo1: file(name: { eq: "fetch-logo-gradient" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo2: file(name: { eq: "tech-week-grand-rapids-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo3: file(name: { eq: "the-right-place-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo4: file(name: { eq: "city-of-grand-rapids-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    logo5: file(name: { eq: "grand-rapids-chamber-of-commerce-logo" }) {
      childImageSharp {
        gatsbyImageData(height: 75)
      }
    }
    jaredImage: file(name: { eq: "Jared-image" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    kevinImage: file(name: { eq: "Kevin-image" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
